<template>
    <div style="height:100%;">
        <a-layout-content style="height:100%;">
            <div class="yj-conten" style="min-height: calc(100% - 48px);background-color:#fff;width:calc(100% - 32px);min-width:max-content">
                <a-card style="width:100%;min-height:100%;" :bordered="false">
                    <a-form-model layout="horizontal" style="padding:10px 00px;height:auto;width:800px;" :rules="rules" ref="ruleForm" :model="MomentModel">
                        <a-form-model-item label="朋友圈标题" style="margin-bottom:10px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol"
                                           prop="Name">
                            <a-input placeholder="请输入标题" v-model="MomentModel.Name" onkeyup="this.value=this.value.replace(/\s+/g,'')" :disabled="disabled"></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="朋友圈作者" style="margin-bottom:10px;display: none;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol"
                                           prop="Author">
                            <a-input placeholder="请输入作者" v-model="MomentModel.Author" onkeyup="this.value=this.value.replace(/\s+/g,'')" :disabled="disabled"></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="朋友圈类型" style="margin-bottom:10px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol">
                            <a-dropdown :trigger="['click']">
                                <a class="ant-dropdown-link" @click="e => e.preventDefault()" :key="Topic.ID" :disabled="disabled">
                                    {{ Topic.Name }}<a-icon type="down" />
                                </a>
                                <a-menu slot="overlay">
                                    <template v-for="item in MaterialTopicList">
                                        <a-menu-item :key="item.ID">
                                            <a @click="changeTopic(item)">{{ item.Name }}</a>
                                        </a-menu-item>
                                    </template>
                                </a-menu>
                            </a-dropdown>
                        </a-form-model-item>
                        <a-form-model-item label="朋友圈内容" style="margin-bottom:10px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol"
                                           prop="Contents">
                            <a-input placeholder="请输入内容" type="textarea" rows="6" v-model="MomentModel.Contents" onkeyup="this.value=this.value.replace(/\s+/g,'')" :disabled="disabled"></a-input>
                        </a-form-model-item>
                        <a-form-model-item label="朋友圈图片" style="margin-bottom:20px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol">
                            <a-upload name="image"
                                      :data="ParamData"
                                      list-type="picture-card"
                                      class="avatar-uploader"
                                      action="/FileServer/Upload"
                                      :fileList="MomentModel.fileList"
                                      @change="imgChange"
                                      :disabled="disabled">
                                <div v-if="MomentModel.fileList.length < 1">
                                    <a-icon type="plus" />
                                    <div class="ant-upload-text">上传图片</div>
                                </div>
                            </a-upload>
                        </a-form-model-item>
                        <a-form-model-item label="选择模版" style="margin-bottom:10px;"
                                           :label-col="formItemLayout.labelCol"
                                           :wrapper-col="formItemLayout.wrapperCol"
                                           prop="Template">
                            <a-input placeholder="点击选择模版" disabled v-model="MomentModel.TemplateName" />
                            <a-button type="primary" value="点击选择" size="small" @click="showTemplate" :disabled="Template.disabled">点击选择</a-button>
                        </a-form-model-item>
                        <a-form-item>
                            <a-button type="primary" class="margin-r" @click="SubmitMoent('ruleForm')" style="margin-left:14rem;" :disabled="disabled">保存</a-button>
                        </a-form-item>
                    </a-form-model>
                </a-card>
            </div>
        </a-layout-content>
        <a-modal v-model="Template.IsDisplay"
                 title="模版选择"
                 centered
                 okText="确定"
                 cancelText="取消"
                 width="900px"
                 height="700px"
                 @ok="onTemplateOk">
            <div style="width: 100%; height:auto;">
                <ul class="form-search">
                    <li style="margin:0">
                        <label>名称</label>
                        <a-input placeholder="输入名称" v-model="Template.Name"></a-input>
                    </li>
                </ul>
                <a-button type="primary" value="查询" @click="getTemplateList" size="small" style="margin-left:12px;height:28px;padding-left:12px;padding-right:12px;">查询</a-button>
                <div id="boxdiv" style="padding-top:16px;">
                    <a-radio-group v-model="Template.ID" @change="onTemplateChange">
                        <table id="example2" class="table  table-hover">
                            <thead>
                                <tr>
                                    <th width="40"></th>
                                    <th width="100">商品图片</th>
                                    <th>名称</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in Template.tableData" :key="item.ID">
                                    <td>
                                        <a-radio :value="item.ID"></a-radio>
                                    </td>
                                    <td align="center">
                                        <img :src="item.PreViewImg" height="80" width="80" />
                                    </td>
                                    <td title="Name">{{item.Name}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </a-radio-group>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script type="text/javascript" charset="utf-8" src="~/Scripts/ueditor.config.js"></script>
<script type="text/javascript" charset="utf-8" src="~/Scripts/ueditor.all.js"></script>
<script type="text/javascript">
    import http from "@/Plugin/Http.js";
    export default {
        name: "MaterialDetails",
        data() {
            return {
                MomentModel: {
                    Name: "",
                    Author: "",
                    Contents: "",
                    fileList: [],
                    TemplateName: ""
                },
                ParamData: {
                    ProcessorName: "Image",
                    JsonDataParams: ""
                },
                MaterialTopicList: [],
                Topic: { Name: "请选择类型", ID: 0 },
                ID: 0,
                QueueID: 0,
                disabled: false,
                headers: {
                    authorization: 'authorization-text',
                },
                rules: {
                    Name: [{ message: "请输入朋友圈标题", required: true, trigger: "blur" }],
                    Author: [{ message: "请输入朋友圈作者", required: true, trigger: "blur" }],
                    Contents: [{ message: "请输入朋友圈内容", required: true, trigger: "blur" }]
                },
                formItemLayout: {
                    labelCol: {
                        xs: { span: 24 },
                        sm: { span: 3 },
                    },
                    wrapperCol: {
                        xs: { span: 24 },
                        sm: { span: 20 },
                    },
                },
                config: {
                    rules: [{ type: 'object', required: true, message: 'Please select time!' }],
                },
                rangeConfig: {
                    rules: [{ type: 'array', required: true, message: 'Please select time!' }],
                },
                Template: {
                    ID: 0,
                    IsDisplay: false,
                    Name: '',
                    tableData: [],
                    disabled: false
                },
            };
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: 'time_related_controls' });
        },
        props: {},//组件属性
        methods: {
            imgChange: function (info) {
                var self = this;
                if (info.file.status === 'error') {
                    if (info.file.response && info.file.response.Message) {
                        this.$message.error(info.file.response.Message);
                    }
                    else {
                        this.$message.error(`${info.file.name} 文件上传失败`);
                    }
                    return;
                }
                if (info.file.status === 'done') {
                    info.file.url = info.file.response;

                }
                self.MomentModel.fileList = info.fileList;
            },
            GetMaterialTopic: function () {
                var self = this;
                var op = {
                    url: "/MarketingModule/Material/GetMaterialTopic",
                    data: {
                    },
                    OnSuccess: function (data) {
                        self.MaterialTopicList = data.data;
                        self.loadData();
                    }
                };
                http.Post(op);
            },
            loadData: function () {
                var self = this;
                if (self.QueueID == 0) {
                    var op2 = {
                        url: "/MarketingModule/Material/GetMaterialInfo",
                        data: {
                            id: self.ID
                        },
                        OnSuccess: function (data) {
                            self.MomentModel.Author = data.data.Author;
                            if (self.ID != 0) {
                                self.MomentModel.Name = data.data.Name;
                                self.MomentModel.Contents = data.data.Content.ContextText;
                                self.Topic = data.data.Topic;
                                self.MomentModel.TemplateName = data.data.MaterialTemplate.Name;
                                var ImgTopic = data.data.Content.ImgTopic;
                                if (ImgTopic != "" && ImgTopic != null) {
                                    self.MomentModel.fileList = [{
                                        name: ImgTopic,
                                        response: ImgTopic,
                                        status: "done",
                                        uid: "0",
                                        url: ImgTopic
                                    }];
                                }
                                else {
                                    self.MomentModel.fileList = [];
                                }
                            }
                        }
                    };
                    http.Post(op2);
                } else {
                    var op = {
                        url: "/MarketingModule/Material/GetMaterialQueueInfo",
                        data: {
                            QueueID: self.QueueID
                        },
                        OnSuccess: function (data) {
                            self.MomentModel.Author = data.data.Author;
                            self.MomentModel.Name = data.data.Name;
                            self.MomentModel.Contents = data.data.Content.ContextText;
                            self.Topic = data.data.Topic;
                            self.MomentModel.TemplateName = data.data.MaterialTemplate.Name;
                            var ImgTopic = data.data.Content.ImgTopic;
                            if (ImgTopic != "" && ImgTopic != null) {
                                self.MomentModel.fileList = [{
                                    name: ImgTopic,
                                    response: ImgTopic,
                                    status: "done",
                                    uid: "0",
                                    url: ImgTopic
                                }];
                            }
                            else {
                                self.MomentModel.fileList = [];
                            }
                        }
                    };
                    http.Post(op);
                }
            },
            SubmitMoent(formName) {
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        var self = this;
                        if (self.checkImage() && self.checkTopic() && self.checkTemplate()) {
                            var op = {
                                url: "/MarketingModule/Material/EditMaterial",
                                data: {
                                    ID: self.ID,
                                    Name: self.MomentModel.Name,
                                    Author: self.MomentModel.Author,
                                    Content: self.GetContent(),
                                    MaterialType: 0,
                                    Topic: self.Topic,
                                    TemplateID: self.Template.ID
                                },
                                OnSuccess: function (response) {
                                    console.log(formName + response);
                                    var con = self.ID == "0" ? "新增" : "编辑";
                                    self.$message.success(con + "成功");
                                    self.$router.push({ name: 'NewMaterial_List' });
                                }
                            };
                            http.Post(op);
                        } else {
                            return false;
                        }
                    }
                });
            },
            GetContent: function () {
                var self = this;
                var Content = { ID: self.ID, ContextText: self.MomentModel.Contents, ImgTopic: self.MomentModel.fileList.length > 0 ? self.MomentModel.fileList[0].response : "" };
                return Content
            },
            checkImage: function () {
                var rt = true;
                var self = this;
                if (self.MomentModel.fileList != null) {
                    if (self.MomentModel.fileList.length == 0) {
                        rt = false;
                        self.$message.error("请上传图片！");
                    }
                }
                return rt;
            },
            checkTopic: function () {
                var rt = true;
                var self = this;
                if (self.Topic.ID == 0) {
                    rt = false;
                    self.$message.error("请选择类型！");
                }
                return rt;
            },
            changeTopic: function (e) {
                var self = this;
                self.Topic = e;
            },
            showTemplate: function () {
                var self = this;
                self.Template.IsDisplay = true;
            },
            onTemplateOk: function () {
                var self = this;
                self.Template.IsDisplay = false;
                var result = self.Template.tableData.filter(function (item) {
                    return item.ID == self.Template.ID;
                });
                self.MomentModel.TemplateName = result[0].Name;
            },
            getTemplateList: function () {
                var self = this
                var op = {
                    url: "/MarketingModule/Material/GetMaterialTemplateList",
                    data: {
                        MaterialType: 0,
                        Name: self.Template.Name
                    },
                    OnSuccess: function (data) {
                        if (data.data.Data.length > 0) {
                            self.Template.tableData = data.data.Data;
                            self.Template.ID = data.data.Data[0].ID;
                        } else {
                            self.Template.tableData = [];
                            self.Template.ID = 0;
                        }
                    }
                };
                http.Post(op);
            },
            onTemplateChange(e) {
                var self = this;
                self.Template.ID = e.target.value;
            },
            checkTemplate: function () {
                var rt = true;
                var self = this;
                if (self.MomentModel.TemplateName == '' && self.ID == 0) {
                    rt = false;
                    self.$message.error("请选择模版！");
                }
                return rt;
            }
        },
        mounted() {
            this.GetMaterialTopic();
            this.getTemplateList();
        },
        created: function () {
            this.ID = this.$route.query.id;
            this.QueueID = this.$route.query.QueueID;
            if (this.$route.query.disabled == 1) {
                this.disabled = true;
            }
            if (this.ID != 0 || this.QueueID != 0) {
                this.Template.disabled = true;
            }
        },//生命周期，创建完成
    }
</script>
<style type="text/css">
    .content {
        min-height: 250px;
        padding: 15px;
        margin-right: auto;
        margin-left: auto;
        padding-left: 15px;
        padding-right: 15px;
    }

    .content {
        margin: 15px 20px 15px 15px;
        padding-top: 0;
    }

    .form-search {
        display: block;
        list-style-type: disc;
        padding-left: 0;
    }

    ul, ol {
        margin-top: 0;
        margin-bottom: 10px;
    }

    .box {
        border-top: none;
        box-shadow: none;
    }

    .right-btn {
        margin-top: 18px;
    }

    .line-btn {
        border: 1px solid #32a9ff;
        color: #32a9ff;
        padding: 7px 15px;
        background: #fff;
        transition: all .15s ease-in-out;
    }

    .gray-line-btn {
        border: 1px solid #e5e5e5;
        color: #666;
        padding: 7px 15px;
        background: #fff;
        transition: all .15s ease-in-out;
        margin-left: 20px;
    }

    .gray-line-btn {
        cursor: pointer
    }

    .solid-btn {
        background: #32a9ff;
        color: #fff !important;
        padding: 4px 18px;
        border: none;
        border-radius: 2px;
        transition: all .15s ease-in-out;
        margin-top: -3px;
    }

        .solid-btn:hover {
            background: #1b99f5;
            cursor: pointer;
            color: #fff
        }

    .num {
        font-size: 26px;
        color: #32a9ff;
        padding: 5px;
        font-weight: 500;
    }

    .inners {
        padding: 10px;
        border: 1px solid #f3f4f5
    }

    .search {
        overflow: hidden;
        background: #fbfbfb;
        margin: 10px auto;
        padding: 10px;
    }

    .line-btn:hover {
        background: #32a9ff;
        color: #fff;
        border-radius: 2px;
        transition: all .15s ease-in-out;
        cursor: pointer;
    }

    .form-search {
        display: block;
        list-style-type: disc;
        padding-left: 0;
    }

    .mtb5 {
        margin: 5px 0
    }

    .mtb10 {
        margin: 10px 0;
    }

    .mtb20 {
        margin: 20px 0;
    }

    .tr-middle td {
        vertical-align: middle;
    }

    body {
        background: #f3f5f5;
        font-family: Arial,Helvetica,sans-serif,"Microsoft YaHei","ËÎÌå";
        font-size: 15px;
    }

    .sys-name {
        color: #00a2e9;
        margin: 0;
        padding: 0;
        font-size: 25px;
        font-weight: 500;
        height: 68px;
        line-height: 68px;
    }

    .right-opt {
        height: 68px;
        line-height: 68px;
        cursor: pointer;
    }

    .top-top {
        margin-bottom: 10px;
        background: #fff;
        border-bottom: solid 1px #E0E5E5;
    }

    .top-center {
        max-width: 1160px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    .content-wrapper {
        background: #fff;
    }

    .content {
        margin: 15px 20px 15px 15px;
        padding-top: 0
    }

    .box-header {
        border-bottom: 1px solid #f3f5f5;
        padding: 0 10px;
    }

        .box-header > .box-title {
            height: 62px;
            line-height: 62px;
        }

    .top-center a[href] {
        color: #32a9ff;
    }

    a {
        color: #32a9ff;
    }

    .pager li > a {
        border: none;
    }

    .main-right-search {
        padding: 20px;
        border-radius: 4px;
        -moz-border-radius: 4px;
        background-color: #fbfbfb;
        padding-top: 0;
    }

    .form-search li {
        float: left;
        overflow: hidden;
        margin-right: 17px;
        width: 350px;
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        border: solid 1px #E0E5E5;
        border-radius: 4px;
        -moz-border-radius: 4px;
        list-style: none;
        margin-top: 8px;
        margin-bottom: 0;
        padding: 0;
    }

        .form-search li label {
            float: left;
            width: 110px;
            color: #666;
            text-align: center;
            border-right: solid 1px #E0E5E5;
        }

        .form-search li input {
            float: left;
            border: none;
            background: none;
            outline: none;
            text-indent: 6px;
            -webkit-appearance: none;
            background-color: #ffffff;
            width: 235px;
            height: 26px;
            color: #333333;
            line-height: 28px;
        }

    .form-search-margin {
        margin-top: 12px;
    }

    .form-search li .inline-text {
        padding-left: 5px;
    }

    .pager {
        padding-left: 0;
        margin: 20px 0;
        text-align: center;
        list-style: none;
    }

        .pager li {
            display: inline;
        }

        .pager ul li {
            list-style: none outside none;
        }

        .pager li > a {
            border: none;
        }

        .pager li > a, .pager li > span {
            display: inline-block;
            padding: 5px 14px;
            background-color: #fff;
            border-radius: 15px;
        }

    .table {
        width: 100%;
        margin-bottom: 20px;
    }
</style>